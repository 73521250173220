import { createTheme } from '@mui/material/styles';
import "@fontsource/montserrat";
import "@fontsource/montserrat/400.css"; 
import "@fontsource/montserrat/400-italic.css"; 

const theme = createTheme({
  components: {
    MuiLinearProgress: {
      styleOverrides: {
        root: {
          height: 20,
          borderRadius: 10,
          backgroundColor: '#e0e0e0',
        },
        bar: {
          borderRadius: 10,
          backgroundColor: '#FFE763',
        },
      },
    },
  MuiAccordion: {
    styleOverrides: {
      root: {
        backgroundColor:'#f5f5f5',
        '&.Mui-disabled': {
          backgroundColor: '#00000000', 
        },
      }
    }
  },
},
  palette: {
    primary: {
      main: '#0029AE', 
      dark:'#053564v',
      contrastText: '#000000', 
    },
    secondary: {
      main: '#E5CD97', 
    },
    error:{
      main:'#A55A45',
    },
    info: {
      main: '#xxxxxx', 
    },
    text: {
      primary: '#262D2B', 
      secondary: '#A55A45', 
      normal: '#2B2B2B',
      bright: '#FFE763',
    },
    level: {
      diamond: '#8A85C9',
      platinum: '#90A9BA',
      gold: '#AE802B',
      silver: '#878788',
      bronze: '#D48A3F',
      normal: '#A5A5A5',
    },
    ui: {
      line: '#CCCCCC', 
      error: '#A5A5A5',
      tabs:'#1B306A',
    },
    progression:{
      kyc: '#EF0000',
      pending:'#E59600',
      incomplete:'#A921FF',
    },
  },
  typography: {
    fontFamily: [
      'Montserrat', 
    ].join(','),
    allVariants: {
      color: '#000000', 
    },
  },
});

export default theme;
