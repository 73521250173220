import React from 'react';
import { Box, CircularProgress } from '@mui/material';
import { ReferralItem } from '../../components/home/referralItem'; 

export const ReferralProgressList = ({ topDownlines, isLoading, getLevelColor, calculateUserLevel, calculateProgressToNextLevel, calculateProgressPercentage }) => (
  <Box>
    {topDownlines.map((referral, index) => (
      <ReferralItem
        key={index}
        referral={referral}
        getLevelColor={getLevelColor}
        calculateUserLevel={calculateUserLevel}
        calculateProgressToNextLevel={calculateProgressToNextLevel}
        calculateProgressPercentage={calculateProgressPercentage}
      />
    ))}
    {isLoading && (
      <Box sx={{ display: 'flex', justifyContent: 'center', p: 2 }}>
        <CircularProgress />
      </Box>
    )}
  </Box>
);
