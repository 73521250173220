import { useTranslation } from 'react-i18next';
import moment from 'moment-timezone';

const milestones = [
  { step: 0, amount: 0 },
  { step: 5, amount: 1500 },
  { step: 10, amount: 3000 },
  { step: 15, amount: 4500 },
  { step: 20, amount: 6000 },
  { step: 40, amount: 10000 }
];

const useReferralProgress = (downlines) => {
  const { t } = useTranslation();

  const calculateWeeklyReferralCount = () => {
    const startOfWeek = new Date();
    startOfWeek.setDate(startOfWeek.getDate() - ((startOfWeek.getDay() + 6) % 7));
    startOfWeek.setHours(2, 0, 0, 0);
    
    const startOfWeekMoment = moment(startOfWeek).startOf('day');

    const validReferrals = downlines.filter(downline => {
      const updatedTimeMoment = moment(downline.updatedTime);
      return downline.referralStatus === "Assigned" &&
             updatedTimeMoment.isSameOrAfter(startOfWeekMoment);
    });
    
    const referralCount = validReferrals.length;
    let progress = 0;
    
    const intervals = milestones.length - 1;
    const percentagePerInterval = 100 / intervals;

    //if (referralCount > 0) {
      //for (let i = 0; i < milestones.length - 1; i++) {
        //if (referralCount >= milestones[i].step && referralCount < milestones[i + 1].step) {
          //progress = i * percentagePerInterval;
          //break;
        //}
      //}

      //if (referralCount >= milestones[milestones.length - 1].step) {
        //progress = 100;
      //} 
    //}

    if (referralCount > 0) {
      for (let i = 0; i < milestones.length - 1; i++) {
        if (referralCount >= milestones[i].step && referralCount < milestones[i + 1].step) {
          const stepRange = milestones[i + 1].step - milestones[i].step;
          const progressInStep = (referralCount - milestones[i].step) / stepRange;
          progress = (i + progressInStep) * (100 / (milestones.length - 1));
          break;
        }
      }

      if (referralCount >= milestones[milestones.length - 1].step) {
        progress = 100;
      }
    }

    return {
      count: referralCount,
      progress
    };
  };

  return calculateWeeklyReferralCount();
};

export default useReferralProgress;
