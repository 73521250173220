import React from 'react';
import { Container, Box, Typography } from '@mui/material';
import BackButton from '../../components/common/backButton';
import TermsBanner from '../../components/terms/termsBanner';
import Section from '../../components/common/section';
import ReferralCodeDisplay from '../../components/terms/referralCodeDisplay';
import { useTranslation } from 'react-i18next';

const TermsPage = () => {
    const { t } = useTranslation(); 

    return (
         <><Box display="flex" alignItems="center" p={1}>
        <BackButton />
        <Typography variant="h6" component="h1" sx={{ textAlign: 'center', fontWeight: 'bold', ml: 2 }}>
        {t('terms')}
        </Typography>
      </Box><TermsBanner />
      <Box  alignItems="center" px={3}>
        <Box mb={2}>
            <Typography fontSize={'1rem'} fontWeight={'bold'}>{t('promotionPeriod')}</Typography>
            <li>{t('promotionDesc')}</li>
            </Box>
            <Box  mb={2}>
            <Typography fontSize={'1rem'} fontWeight={'bold'}>{t('howDoesItWork')}</Typography>
            <li>{t('howDoesItWorkDesc1')}</li>
            <li>{t('howDoesItWorkDesc2')}</li>
            <li>{t('howDoesItWorkDesc3')}</li>
            <li>{t('howDoesItWorkDesc4')}</li>
            <li>{t('howDoesItWorkDesc5')}</li>
            </Box>
            <Box  mb={2}>
            <Typography fontSize={'1rem'} fontWeight={'bold'}>{t('successfulReferralCase')}</Typography>
            <li>{t('successfulReferralCaseDesc1')}</li>
            <li>{t('successfulReferralCaseDesc2')}</li>
            <li>{t('successfulReferralCaseDesc3')}</li>
            </Box>
            <Box mb={2}>
            <Typography fontSize={'1rem'} fontWeight={'bold'}>{t('bonusesReq')}</Typography>
            <Typography fontSize={'1rem'} >{t('bonusesReq1')}</Typography>
            <li>{t('bonusesReqDesc1')}</li>
            <Typography fontSize={'1rem'} >{t('bonusesReq2')}</Typography>
            <li>{t('bonusesReq2Desc1')}</li>
            <li>{t('bonusesReq2Desc2')}</li>
            <li>{t('bonusesReq2Desc3')}</li>
            <li>{t('bonusesReq2Desc4')}</li>
            <li>{t('bonusesReq2Desc5')}</li>
            <li>{t('bonusesReq2Desc6')}</li>
            <Typography fontSize={'1rem'} >{t('bonusesItalicText')}</Typography>
            </Box>
            <Box mb={2}>
            <Typography fontSize={'1rem'} fontWeight={'bold'}>{t('otherTerms')}</Typography>
            <li>{t('otherTermsDesc1')}</li>
            <Typography fontSize={'1rem'} >{t('bonusesReq2')}</Typography>
            <li>{t('otherTermsDesc2')}</li>
            <li>{t('otherTermsDesc2')}</li>
            <li>{t('otherTermsDesc3')}</li>
            <li>{t('otherTermsDesc4')}</li>
            <li>{t('otherTermsDesc5')}</li>
            <li>{t('otherTermsDesc6')}</li>
            <li>{t('otherTermsDesc7')}</li>
            <li>{t('otherTermsDesc8')}</li>
            <li>{t('otherTermsDesc9')}</li>
            </Box>
        </Box></>
    );
  };

export default TermsPage;
