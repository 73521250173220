import React from 'react';
import { Box, Typography, Button, Modal } from '@mui/material';
import MachiSpin from '../../assets/images/2024_03_Lucky Spin Widget.png'
import { useTranslation } from 'react-i18next';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 350,
  boxShadow: 24,
  borderRadius: 2,
  p: 1,
  bgcolor:'silver',
};
const storedUsername = btoa(localStorage.getItem('Username'));
const SpinWheelModal = ({ open, handleClose }) => {
  const { t } = useTranslation(); 
  return (
  <Modal
    open={open}
    onClose={handleClose}
    aria-labelledby="spin-wheel-modal-title"
    aria-describedby="spin-wheel-modal-description"
  >
  <Box sx={style}>
  <img src={MachiSpin} alt="Machi Spin" style={{ height: '120px', margin: '0 auto', display: 'block' }} />
      <Typography id="spin-wheel-modal-title" variant="h6" component="h2" sx={{ my: 0, textAlign:'center', fontWeight:'bold' }}>
        {t('readyForReward')}
      </Typography>
      <Typography id="spin-wheel-modal-description" sx={{ fontSize:'0.65rem', mb: 0, textAlign:'center' }}>
        {t('doYouWantToEarnMoreRewards')}
      </Typography>
      <Box sx={{textAlign:'center'}}>
      <Button onClick={handleClose} sx={{ m: 1, color:'white', fontWeight:'bold' }} variant="contained" color="primary">
        {t('stay')}
      </Button>
      <Button  onClick={() => { handleClose(); window.location.href = `https://www.machispinwin.com/LuckySpin/Landing?data=${storedUsername}&Locale=bn`; }} sx={{ m: 1, fontWeight:'bold' }} variant="contained" color="secondary">
      {t('spinNow')}
      </Button>
      </Box>
    </Box>
  </Modal>
  );
};
export default SpinWheelModal;
