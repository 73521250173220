import React, { useState,useEffect } from 'react';
import RewardsMain from '../../components/reward/rewardMainDetails';
import { useNavigate } from 'react-router-dom'; 


const RewardsDetailsPage = ({ greetingInfo, memberInfo, coinInfo, isLoading }) => {
  const navigate = useNavigate(); 
  useEffect(() => {
    if (!greetingInfo || Object.keys(greetingInfo).length === 0) {
      navigate(-1);
    }
  }, [greetingInfo, navigate]);
  return (
    <RewardsMain greetingInfo={greetingInfo} memberInfo={memberInfo} coinInfo={coinInfo} isLoading={isLoading}/>
  );
};

export default RewardsDetailsPage;
