import React, { useEffect, useState } from 'react';
import { Typography, Box, styled, LinearProgress  } from '@mui/material';

export const ProgressLabel = ({ value, label, hasStatusIcon, isKYCFailed  }) => {
    const [progress, setProgress] = useState(0);

    const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
      [`&.${LinearProgress.colorPrimary}`]: {
        backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? '200' : '800'],
      },
      [`& .MuiLinearProgress-bar`]: {
        transition: 'transform .8s ease-in-out',
        backgroundColor: hasStatusIcon ? theme.palette.level.normal: theme.palette.text.bright,
      },
    }));
    
  
    useEffect(() => {
      setProgress(0);
      const timer = setInterval(() => {
          setProgress((oldProgress) => {
            const diff = value - oldProgress;
            if (diff > 0) {
              return Math.min(oldProgress + Math.max(diff / 10, 1), value); 
            }
            clearInterval(timer);
            return value;
          });
        }, 20);
    
        return () => {
          clearInterval(timer);
        };
    }, [value]);
  
    return (
      <Box sx={{ position: 'relative', width: '100%', height: 20 }}>
        <BorderLinearProgress variant="determinate" value={progress} sx={{bgcolor: '#CCCCCC'}}/>
        <Box
          sx={{
            position: 'absolute',
            left: 0,
            top: 0,
            bottom: 0,
            width: progress < 10 ? 'empty' : `${Math.min(progress, 100)}%`, 
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end', 
            p: 1, 
            color: 'text.secondary',
            overflow: 'hidden', 
          }}
        >
          <Typography variant="caption" component="div" color={ hasStatusIcon ? 'white':'primary'} noWrap sx={{fontWeight:'bold', fontSize:'0.6rem', }}>
            {isKYCFailed ? 'N/A' : label}
          </Typography>
        </Box>
      </Box>
    );
  };
  