import React from 'react';
import ReferralStatus from '../common/referralStatus';
import { Box, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const ReferralList = ({ topDownlines = [], isLoading }) => {
  const { t } = useTranslation();

  return (
    <Box>
      <ReferralStatus topDownlines={topDownlines} isLoading={isLoading} limit={3} />
      <Box textAlign={'center'} mt={-3}>
        <Typography variant="caption" color="textSecondary">
          <Link to={{
                  pathname: "/status",
                  state: { topDownlines }
                }} 
                style={{ color: 'blue', textDecoration: 'underline', cursor:'pointer', textAlign:'center', paddingTop:'5px' }}>
                {topDownlines != "" ? t('more') : "" }
              </Link>
        </Typography>
      </Box>
    </Box>
  );
};
export default ReferralList;
