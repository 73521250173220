import React from 'react';
import { Box, Typography, LinearProgress } from '@mui/material';
import useReferralProgress from '../../hooks/useWeeklyReferralProgress';
import MonetizationOnIcon from '../../assets/images/Lucky Spin_Gold_Assets_coin.png';
import { useTranslation } from 'react-i18next';

const WeeklyTargetReferralProgress = ({ downlines }) => {
  const { t } = useTranslation(); 

  const milestones = [
    { step: 0, amount: 0 },
    { step: 5, amount: 1500 },
    { step: 10, amount: 3000 },
    { step: 15, amount: 4500 },
    { step: 20, amount: 6000 },
    { step: 40, amount: 10000 }
  ];
  const { count, progress } = useReferralProgress(downlines);

  return (
    <Box sx={{
      borderRadius: 2,
      mx: 3,
      my: 2,
      boxShadow: 1,
      border: '4px solid #FFCE00',
      backgroundImage: 'linear-gradient(to bottom, #123FA5, #213380)',
      color: 'white',
      padding: '12px'
    }}>
      <Typography variant="subtitle2" sx={{ fontWeight: 'bold', textAlign: 'left', color:'#FFCE00' }}>
        {t('weeklyTargetReferral')}
      </Typography>
      <Typography sx={{ fontWeight: 'bold', textAlign: 'left', color:'#FFCE00', fontSize:'0.75rem' }}>
      {`${count || 0} ${t('referrals')}`}
      </Typography>
      <Box sx={{ position: 'relative', height: 10, marginTop: 7, mx: 1, mb:4, width: '100%' }}>
        <LinearProgress 
          variant="determinate" 
          value={progress}
          sx={{ 
            height: 5, 
            borderRadius: 5, 
            mx: 1.2, 
            mr: 1.5, 
            '& .MuiLinearProgress-bar': { 
              bgcolor: '#FFCE00',
              transition: 'width 0.8s ease-in-out', 
            },
            '& .MuiLinearProgress-root': { 
              bgcolor: 'rgba(255, 215, 0, 0.5)'
            },
          }} 
        />
        <Box sx={{
          display: 'flex',
          position: 'absolute',
          left: 0,
          right: 0,
          justifyContent: 'space-between',
          height: 40, 
        }}>
          {milestones.map((milestone, index) => {
            const isAchieved = count >= milestone.step;
            const showAmount = count <= milestone.step; 
            return (
              <Box
                key={index}
                sx={{
                  position: 'relative',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  width: 24,
                  height: 24,
                  bgcolor: isAchieved ? '#FFCE00' : 'white',
                  borderRadius: '50%',
                  transform: 'translate(-50%, -60%)'
                }}
              >
                {showAmount && milestone.amount > 0 && (
              <Box sx={{
                position: 'absolute',
                left: '50%',
                transform: 'translate(-50%, -150%)',
                bgcolor: isAchieved ? 'white' : 'rgba(255, 255, 255, 0.9)', 
                color: 'black',
                borderRadius: '10px',
                padding: '2px 3px',
                boxShadow: '0 2px 10px rgba(0,0,0,0.2)',
                '&::after': {
                  content: '""',
                  position: 'absolute',
                  top: '100%',
                  left: '50%',
                  transform: 'translateX(-50%)',
                  width: 0,
                  height: 0,
                  borderLeft: '5px solid transparent',
                  borderRight: '5px solid transparent',
                  borderTop: '5px solid white'
                }
              }}>
                <Typography sx={{ fontSize: '0.65rem', fontWeight: 'bold', display: 'flex', alignItems: 'center', gap:0.15,
                      color: count >= milestone.step ? '#000' : '#888'
                 }}>
                  {`${milestone.amount} `}
                  <img src={MonetizationOnIcon} alt="Coin" style={{ height: 10 }} />
                </Typography>
                </Box>
                )}
                <Typography sx={{ fontSize: '0.75rem', fontWeight: 'bold', color: isAchieved ? '#000' : '#888' }}>
                  {milestone.step}
                </Typography>
              </Box>
            );
          })}
        </Box>
        
      </Box>
      <Box sx={{ mt: 2, textAlign: 'left' }}>
    <Typography sx={{ fontSize: '0.6rem', fontStyle: 'italic', color:'#FFCE00' }}>
      {t('weeklyTargetReferralDesc')}
    </Typography>
  </Box>
    </Box>
  );
};

export default WeeklyTargetReferralProgress;
