/**
 * Masks the username by keeping the first two and the last characters.
 * Replaces the characters in between with asterisks (*).
 * @param {string} username The username to mask.
 * @return {string} The masked username.
 */
const maskUsername = (username) => {
    if (username.length <= 3) {
      return username;
    }
    
    const firstTwoChars = username.slice(0, 2);
    const lastChar = username.slice(-1);
    const maskedSection = '*'.repeat(3);
  
    return `${firstTwoChars}${maskedSection}${lastChar}`;
  };
  
  export default maskUsername;