import { useContext, useMemo } from 'react';
import { UserInfoContext } from '../contexts/userInfoContext';
import { formatDateTime } from '../utils/dateFormatUtils';
import moment from 'moment';

const sumByCondition = (items, condition) => items.reduce((acc, item) => {
  return condition(item) ? acc + parseFloat(item.credit || 0) : acc;
}, 0);

export const useUserInfo = () => {
  const { userInfo, isLoading, error } = useContext(UserInfoContext);

  const greetingInfo = useMemo(() => {
    if (!userInfo) return null;
    const { downlines = [], referrerRewards = [], userName, referrerCode, downlineVIPBonus = [], weeklyBonusHistory = [], downlinesCount } = userInfo.data;
    let totalReferralBonus = 0;
    let accumulatedTotalRewards = 0;
    let vipBonusTotal = downlineVIPBonus.reduce((acc, bonus) => acc + bonus.bonus, 0);
    let totalCoins = downlineVIPBonus.reduce((acc, bonus) => acc + bonus.coins, 0);  

    const { totalRewards, availableRewards, downlinesTurnoverComm, referralBonus, invalidBonus,
            downlinesTurnoverCommList, referralBonusList } = referrerRewards.reduce((acc, item) => {
      const credit = parseFloat(item.credit || 0);
      acc.totalRewards += credit;

      if (item.status === "0") {
        acc.availableRewards += credit;
      }
      if (item.promo_code === "Referral004-C") {
        acc.downlinesTurnoverComm += credit;
        acc.downlinesTurnoverCommList.push(item);
      } else {
        acc.referralBonus += credit;
        acc.referralBonusList.push(item);
      }
      if (item.status === "3") {
        acc.invalidBonus += credit;
      }
      return acc;
    }, {
      totalRewards: 0,
      availableRewards: 0,
      downlinesTurnoverComm: 0,
      referralBonus: 0,
      invalidBonus: 0,
      downlinesTurnoverCommList: [],
      referralBonusList: [],
    });
    totalReferralBonus += referralBonus + vipBonusTotal; // Adding VIP bonuses to referralBonus
    accumulatedTotalRewards += totalRewards + vipBonusTotal; // Adding VIP bonuses to total rewards
    //const totalDownlines = downlines.length;
    const totalDownlines = downlinesCount;
    const successDownlines = downlines.filter(({ referralStatus }) => referralStatus === "Assigned").length;

    // Sorting logic including KYC status check
    const topDownlines = downlines.sort((a, b) => {
      const kycA = a.refereeKYCStatus === "Rejected";
      const kycB = b.refereeKYCStatus === "Rejected";
      if (kycA && !kycB) return 1;
      if (!kycA && kycB) return -1;
      return b.refereeTotalDeposit - a.refereeTotalDeposit;
    });

    return {
      userName,
      totalDownlines,
      success: successDownlines,
      rewards: accumulatedTotalRewards,
      referralCode: referrerCode,
      topDownlines,
      availableRewards,
      downlinesTurnoverComm,
      referralBonus: totalReferralBonus,
      invalidBonus,
      downlinesTurnoverCommList,
      referralBonusList,
      totalCoins,  // Adding vip totalCoins 
      vipBonusTotal, // Adding vip totalBonus,
      downlineVIPBonus,
      lastUpdateDate: formatDateTime(moment()),
      weeklyBonusHistory,
    };
  }, [userInfo]);

  return { greetingInfo, isLoading, error };
};
