import React, { useState } from 'react';
import { CardContent, Typography, Skeleton, Box,IconButton, Grid, Slide } from '@mui/material';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import CheckIcon from '@mui/icons-material/Check';
import SocialShare from '../terms/socialShare';
import QRCode from 'qrcode.react';
import { useTheme } from '@mui/material/styles';
import CustomSnackbar from '../common/snackBar'; 
import { useTranslation } from 'react-i18next';
import ShareButton from '../../components/common/shareButton';

const ReferralCode = ({ code, isLoading }) => {
  const theme = useTheme();
  const [copied, setCopied] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [modalOpen, setModalOpen] = useState(false);
  const { t } = useTranslation(); 
  const urlToShare = `https://m.mcb777.com/signup?ref=${code}`;

  const handleCopy = () => {
    navigator.clipboard.writeText(urlToShare);
    setCopied(true);
    setSnackbarOpen(true); 
    setSnackbarMessage('Copied Successfully!');
    setTimeout(() => setCopied(false), 2000); 
  };

  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };

  return (
    <CardContent>
      <Grid container spacing={2} alignItems="center" justifyContent="center">
      <Grid item>
          {isLoading ? (
            <Skeleton variant="rectangular" width={100} height={100} animation="wave"/>
          ) : (
            <QRCode value={urlToShare} size={100} level="L" />
          )}
        </Grid>
        <Grid item xs>
          {isLoading ? (
            <Skeleton variant="text" width="80%" height={24} animation="wave"/>
          ) : (
            <Typography variant="h7" fontSize={'0.8rem'} component="h4" gutterBottom>
              {t('referralCode')}
            </Typography>
          )}
          {isLoading ? (
            <Skeleton variant="text" width="60%" height={30} animation="wave"/>
          ) : (
            <Typography variant="h6" component="p" gutterBottom fontWeight={'bold'} sx={{}}>
              {code}
              <IconButton color="primary" onClick={handleCopy} size='small'>
                {copied ? <CheckIcon fontSize="small"/> : <ContentCopyIcon fontSize="small"/>}
              </IconButton>
            </Typography>
          )}
          {isLoading ? (
            <Skeleton variant="rectangular" width={140} height={36} animation="wave"/>
          ) : (
            <Box sx={{ display: 'flex', justifyContent: 'left', gap: 1 }}>
              <ShareButton urlCode={urlToShare}/>
            </Box>
          )}
        </Grid>
      </Grid>
      <SocialShare open={modalOpen} onClose={() => setModalOpen(false)} url={urlToShare} TransitionComponent={Slide} TransitionProps={{ direction: 'up' }} />
      <CustomSnackbar open={snackbarOpen} handleClose={handleCloseSnackbar} message={snackbarMessage} />
    </CardContent>
  );
};

export default ReferralCode;
