import { useContext, useMemo } from 'react';
import { UserInfoContext } from '../contexts/userInfoContext';

export const useSpinMemberInfo = () => {
  const { spinMemberInfo, isLoading, error } = useContext(UserInfoContext);

  const memberInfo = useMemo(() => {
    if (!spinMemberInfo) return null;

    const { data } = spinMemberInfo;

    return data.memberCredits; 
  }, [spinMemberInfo]);
  
  return { memberInfo, isLoading, error };
};
