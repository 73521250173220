import React, { useEffect, useState } from 'react';
import { MemoryRouter as Router, Routes, Route } from 'react-router-dom';
import HomePage from './pages/Home';
import TermsPage from './pages/Terms';
import RewardsDetailsPage from './pages/RewardsDetails';
import ReferralStatusPage from './pages/ReferralStatus';
import { useUserInfo } from './hooks/useUserInfo'; 
import { useSpinMemberInfo } from './hooks/useSpinMemberInfo';
import { useSpinCoinInfo } from './hooks/useCoinInfo';

const AppRoutes = () => {
  const { greetingInfo: userInfo, isLoading: userInfoLoading, error: userInfoError } = useUserInfo();
  const { memberInfo: spinMemberInfo } = useSpinMemberInfo();
  const { coinInfo: spinCoinInfo } = useSpinCoinInfo();


  const HomePageWithUserInfo = (props) => (
    <HomePage greetingInfo={userInfo} memberInfo={spinMemberInfo} coinInfo={spinCoinInfo} isLoading={userInfoLoading} error={userInfoError} {...props} />
  );
  
  const RewardsDetailsPageWithUserInfo = (props) => (
    <RewardsDetailsPage greetingInfo={userInfo} memberInfo={spinMemberInfo} coinInfo={spinCoinInfo} isLoading={userInfoLoading} error={userInfoError} {...props} />
  );

  const ReferralStatusPageWithUserInfo = (props) => (
    <ReferralStatusPage greetingInfo={userInfo} isLoading={userInfoLoading} error={userInfoError} {...props} />
  );


  return (
    <Router>
      <Routes>
        <Route path="/" element={<HomePageWithUserInfo />} />
        <Route path="/terms" element={<TermsPage />} />
        <Route path="/rewards" element={<RewardsDetailsPageWithUserInfo />} />
        <Route path="/status" element={<ReferralStatusPageWithUserInfo />} />
      </Routes>
    </Router>
  );
};

export default AppRoutes;
