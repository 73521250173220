import React, { useState } from 'react';
import { Box, Button, IconButton, InputBase, Paper, Typography, Modal, Snackbar } from '@mui/material';
import { Facebook as FacebookIcon, Instagram as InstagramIcon, Telegram as TelegramIcon, WhatsApp as WhatsAppIcon, Share as ShareIcon } from '@mui/icons-material';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import CheckIcon from '@mui/icons-material/Check';
import LinkIcon from '@mui/icons-material/Link';
import CustomSnackbar from '../common/snackBar'; 

const SocialShare = ({url,open, onClose,}) => {
    const [copied, setCopied] = useState(false);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');

        const handleCopyToClipboard = () => {
            navigator.clipboard.writeText(url).then(() => {
                setCopied(true);
                setSnackbarOpen(true); 
                setSnackbarMessage('Copied Successfully!');
                setTimeout(() => setCopied(false), 4000);
            }, (err) => {
              setSnackbarMessage('Failed to copy text');
              setSnackbarOpen(true);
            });
    }

    const handleCloseSnackbar = () => {
        setSnackbarOpen(false);
      };
        
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 350,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 2,
    borderRadius: 2,
  };

  const handleSocialShare = (social) => {
    let shareUrl = '';

    switch (social) {
      case 'Facebook':
        shareUrl = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(url)}`;
        break;
      case 'Telegram':
        shareUrl = `https://t.me/share/url?url=${encodeURIComponent(url)}`;
        break;
      case 'WhatsApp':
        shareUrl = `https://api.whatsapp.com/send?text=${encodeURIComponent(url)}`;
        break;
      case 'Instagram':
        window.open('instagram://app');
        break;
      case 'Others':
        if (navigator.share) {
          navigator.share({
            title: 'Check this out!',
            text: 'Take a look at this awesome page.',
            url,
          }).catch((error) => {
            console.log('Error sharing:', error);
          });
        } 
        return;
      default:
        return;
    }
    
    if (shareUrl) {
        window.open(shareUrl, '_blank', 'noopener,noreferrer');
      }
    };

  return (
    <><Modal open={open} onClose={onClose}>
          <Box sx={style}>
              <Typography variant="subtitle1" gutterBottom>Share via</Typography>
              <Box sx={{ display: 'flex', justifyContent: 'space-around', marginBottom: 2 }}>
                  <IconButton onClick={() => handleSocialShare('Facebook')}>
                      <FacebookIcon />
                  </IconButton>
                  <IconButton onClick={() => handleSocialShare('Instagram')}>
                      <InstagramIcon />
                  </IconButton>
                  <IconButton onClick={() => handleSocialShare('Telegram')}>
                      <TelegramIcon />
                  </IconButton>
                  <IconButton onClick={() => handleSocialShare('WhatsApp')}>
                      <WhatsAppIcon />
                  </IconButton>
                  <IconButton onClick={() => handleSocialShare('Others')}>
                      <ShareIcon />
                  </IconButton>
              </Box>
              <Typography variant="subtitle1" gutterBottom>Or copy link</Typography>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <Paper component="form" sx={{ display: 'flex', alignItems: 'center', mr: 1, flex: 1, borderRadius: 0 }}>
                  <LinkIcon aria-label="link" sx={{pl:1}}/>
                      <InputBase
                          sx={{ ml: 1, flex: 1 }}
                          value={url}
                          inputProps={{ 'aria-label': 'copy referral link' }}
                          readOnly />
                      <IconButton aria-label="copy" onClick={handleCopyToClipboard}>
                          {copied ? <CheckIcon /> : <ContentCopyIcon />}
                      </IconButton>
                  </Paper>
                  {/*<Button variant="contained" onClick={handleCopyToClipboard} sx={{borderRadius:1}}>
        COPY
  </Button>*/}
              </Box>
          </Box>
      </Modal>      
      <CustomSnackbar open={snackbarOpen} handleClose={handleCloseSnackbar} message={snackbarMessage} />
</>
  );
};

export default SocialShare;
