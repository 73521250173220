import React from 'react';
import { Container, Box,Typography } from '@mui/material';
import UserGreeting from '../../components/home/userGreeting';
import RewardsSummary from '../../components/home/rewardSummary';
import ReferralCode from '../../components/home/referralCode';
import ReferralList from '../../components/home/referralList';
import Banner from '../../components/common/banner';
import CloseButton from '../../components/common/closeButton';
import LanguageSelector from '../../components/common/languageSelector';
import MachiBanner from '../../components/common/machiBanner';
import RewardCardComponent from '../../components/home/availableRewardBox';

const HomePage = ({ greetingInfo, coinInfo, isLoading, error }) => {

  const totalCoinsEarned = coinInfo.reduce((sum, item) => sum + item.bonus, 0);
  const totalCoins = greetingInfo?.totalCoins ? greetingInfo.totalCoins + totalCoinsEarned : totalCoinsEarned;

  return (
    <>
      <Box display="flex" justifyContent="space-between" alignItems="center" p={1}>
        <CloseButton />
        <MachiBanner />
        <LanguageSelector />
      </Box>
      <Container>
        <Box sx={{ textAlign: 'center' }}><Banner /></Box>
        <UserGreeting {...greetingInfo} isLoading={isLoading} />
        {greetingInfo?.availableRewards != 0 ? <RewardCardComponent reward={greetingInfo?.availableRewards} isLoading={isLoading}/> : ""}
        <RewardsSummary userInfo={greetingInfo} totalCoins={totalCoins} isLoading={isLoading} />
        <Box sx={{ my: 2 }}>
          <ReferralList topDownlines={greetingInfo?.topDownlines} isLoading={isLoading} />
        </Box>
        <ReferralCode code={greetingInfo?.referralCode} isLoading={isLoading} />
      </Container>
    </>
  );
};

export default HomePage;
