import { useEffect } from 'react';

 const useGoogleAnalytics = (gaId) => {
  useEffect(() => {
    const script = document.createElement('script');
    script.async = true;
    script.src = `https://www.googletagmanager.com/gtag/js?id=${gaId}`;
    document.head.appendChild(script);

    script.onload = () => {
      window.dataLayer = window.dataLayer || [];
      function gtag() { window.dataLayer.push(arguments); }
      gtag('js', new Date());
      gtag('config', gaId);
    };

    return () => {
      document.head.removeChild(script);
    };
  }, [gaId]);
};

export default useGoogleAnalytics;
