import React, { useState, useEffect }  from 'react';
import { Container, Typography, Box} from '@mui/material';
import BackButton from '../../components/common/backButton';
import ReferralStatusList from '../../components/common/referralStatus';
import ReferralData from '../../components/common/referralsData';
import ShareButton from '../../components/common/shareButton';
import { useNavigate } from 'react-router-dom'; 
import { useTranslation } from 'react-i18next';

const ReferralStatusPage = ({ greetingInfo, isLoading, error }) => {
  const { t } = useTranslation(); 
  const navigate = useNavigate(); 
  useEffect(() => {
    if (!greetingInfo || Object.keys(greetingInfo).length === 0) {
      navigate(-1);
    }
  }, [greetingInfo, navigate]);
  const urlToShare = `https://m.mcb777.com/signup?ref=${greetingInfo?.referralCode}`;

  return (
    <><Box display="flex" alignItems="center" p={1}>
          <BackButton />
          <Typography variant="h6" component="h1" sx={{textAlign: 'center', fontWeight: 'bold', ml:2 }}>
          {t('referralStatus')}
                  </Typography>
      </Box>
      <Container>
      <ReferralData referrals={greetingInfo?.totalDownlines} success={greetingInfo?.success} isLoading={isLoading}/>
      <Box sx={{ my: 2 }}>
        <ReferralStatusList topDownlines={greetingInfo?.topDownlines} isLoading={isLoading}/>
      </Box>
      <Box sx={{ my: 0 }}>
        <Typography sx={{fontWeight:'bold', fontSize:'2rem', textAlign:'center'}}>
        {t('inviteNow')}
        </Typography>
      </Box>
      <Box>
        <Typography sx={{fontSize:'0.75rem', textAlign:'center'}}>
        {t('elevateVipStatus')}
        </Typography>
      </Box>
      <Box>
        <Typography sx={{fontSize:'1rem', textAlign:'center', fontWeight:'bold'}}>
        {t('elevateVipStatus1')}
        </Typography>
      </Box>
      <Box>
        <Typography sx={{fontSize:'0.75rem', textAlign:'center'}}>
        {t('elevateVipStatus2')}
        </Typography>
      </Box>
      <Box sx={{my:2.5}}> 
        <ShareButton urlCode={urlToShare}/>
      </Box>
      </Container>
      </>
  );
};

export default ReferralStatusPage;
