import React from 'react';
import { Card, CardContent, Typography, Box, Skeleton } from '@mui/material';
import MonetizationOnIcon from '../../assets/images/Lucky Spin_Gold_Assets_coin.png';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import CountUp from 'react-countup'; 

const RewardsSummary = ({ userInfo, totalCoins, isLoading }) => {
  const { t } = useTranslation(); 

  return (
    <Card sx={{ background: '#0029AE', color: 'white' }}>
      <CardContent sx={{ textAlign: 'center', my:-1 }}>
        {isLoading ? (
          <Skeleton variant="text" width={210} height={30} animation="wave"/> 
        ) : (
          <Typography gutterBottom sx={{ fontWeight: 'bold', my: 0, color:'white', mb:'-0.5em' }}>
            {t('lifetimeCashReward')}
          </Typography>
        )}

        {isLoading ? (
          <Skeleton variant="text" width={180} height={20} animation="wave"/>
        ) : (
          <Typography variant='caption' sx={{ fontSize: 10, my: 0, color:'white'}}>
            {t('lastUpdate', { date: userInfo?.lastUpdateDate })}
          </Typography>
        )}

        {isLoading ? (
          <Skeleton variant="rectangular" width={250} height={48} animation="wave"/> 
        ) : (
          <Typography variant="h4" component="h3" sx={{ fontWeight: 'bold', my: 0,  }} color={'#FFE763'}> 
            <CountUp end={userInfo?.rewards} duration={2.75} decimals={2} /> {t('BDT')}
          </Typography>
        )}

    <Box sx={{ display: 'flex', justifyContent: 'space-between', p: '8px 0 8px' }}>
      {isLoading ? (
        <Skeleton variant="rectangular" width="50%" height={60} animation="wave"/>
      ) : (
        <Box sx={{ textAlign: 'left', width: '50%', backgroundColor:'#1B306A', borderRadius:2, mr:0.5, p: 0.5 }}>
          <Typography variant="caption" color={'white'} sx={{ fontSize: '0.55rem' }}>
            {t('referralBonus')}
          </Typography>
          <Typography variant="h6" color={'#FFE763'} sx={{ fontSize: '0.75rem', fontWeight: 'bold' }}>
          <CountUp end={userInfo?.referralBonus} duration={1.1} decimals={2}/> {t('BDT')}
          </Typography>
        </Box>
      )}
      {isLoading ? (
        <Skeleton variant="rectangular" width="50%" height={60} animation="wave"/>
      ) : (
        <Box sx={{ textAlign: 'left', width: '50%', backgroundColor:'#1B306A', borderRadius:2,  ml:0.5, p: 0.5 }}>
          <Typography variant="caption" color={'white'} sx={{ fontSize: '0.55rem' }}>
            {t('downlineTurnoverCommission')}
          </Typography>
          <Typography variant="h6" color={'#FFE763'} sx={{ fontSize: '0.75rem', fontWeight: 'bold' }}>
          <CountUp end={userInfo?.downlinesTurnoverComm} duration={1.1} decimals={2}/> {t('BDT')}
          </Typography>
        </Box>
      )}
    </Box>
      <Box sx={{
        backgroundColor: 'white', 
        borderRadius: 6, 
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '2px 16px',
        width: 'fit-content', 
        margin: 'auto',
      }}>
         {isLoading ? (
        <Skeleton variant="circular" width={30} height={30} animation="wave"/>
      ) : (
        <img src={MonetizationOnIcon} style={{ height: 30, marginLeft:'-10px' }} /> 
      )}
      {isLoading ? (
        <Skeleton variant="text" width={100} height={30} animation="wave"/>
      ) : (
        <>
          <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'start' }}>
  <Typography variant="subtitle1" sx={{ fontSize: '0.5rem', fontWeight: 'bold', mr: 1, paddingLeft: 1 }}>
    {t('totalCoinEarned')}
  </Typography>
  <Typography variant="h6" sx={{ fontSize: '0.75rem', fontWeight: 'bold',  mr: 1, paddingLeft: 1,  }}>
    <CountUp end={totalCoins} duration={1.1} />
  </Typography>
</Box>
        </>
      )}
        </Box>
        {isLoading ? (
          <Skeleton variant="text" width={100} height={30} animation="wave"/>
        ) : (
          <Typography variant="caption">
            <Link to="/rewards" style={{ color: 'white', textDecoration: 'underline', cursor:'pointer' }}>
            {userInfo != null ? t('moreDetails') : "" }
            </Link>
          </Typography>
        )}
      </CardContent>
    </Card>
  );
};

export default RewardsSummary;
