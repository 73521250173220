import React from 'react';
import { Card, Typography, Box, Skeleton } from '@mui/material';
import {Link} from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const RewardCard = ({ reward, isLoading }) => {
  const { t } = useTranslation(); 

  return (
     <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'start', justifyContent: 'center',
     width: 'fit-content', 
     margin: 'auto'}}>
      {isLoading ? (
        <Skeleton variant="rectangular" width={200} height={60} sx={{mb:1}} animation="wave" />
      ) : (
        <Card 
      sx={{
        borderRadius: 1,
        textAlign: 'center',
        boxShadow: `
        inset 4px 4px 4px 0px #00000033,
        inset 0 0px 8px -4px #00000033
      `,
        mb:1, 
        p:0.5,
        minWidth:200
      }}
    >
        <Typography sx={{ fontSize: "0.55rem", fontWeight: 'bold', mb:'-0.35em' }} color="black" gutterBottom>
        {t('availableRewards')}
        </Typography>
        <Link to="https://m.mcb777.com/member/rewards" style={{ 
          color: '#0029AE',
          fontWeight: 'bold',
          textDecoration: 'underline',
          cursor: 'pointer',
          fontSize:'0.75rem'
        }}>
          {reward == undefined ? "" : reward+ " BDT"}
        </Link>
    </Card>
      )}
    </Box>
  );
};

export default RewardCard;