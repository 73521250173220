import React, { useState } from 'react';
import { Container, Typography, Box, Button } from '@mui/material';
import BackButton from '../../components/common/backButton';
import { useTheme } from '@mui/material/styles';
import LifetimeCashView from './lifetimeCashReward';
import CoinsView from './totalCoinsEarned';
import CoinsDetails from './totalCoinsEarnedDetails';
import LifetimeCashDetails from './lifetimeCashRewardDetails';
import LifetimeCashRecords from './lifetimeCashRewardRecords';
import CoinsRewardRecords from './totalCoinsRecords';
import WeeklyTargetReferralProgress from './weeklyTargetReferral';
import { useTranslation } from 'react-i18next';

const RewardsMain = ({greetingInfo, memberInfo, coinInfo, isLoading}) => {
  const { t } = useTranslation(); 
  const [activeTab, setActiveTab] = useState('lifetimeCash');
  const theme = useTheme();
  const totalCoinsEarned = coinInfo.reduce((sum, item) => sum + item.bonus, 0);
  const totalCoins = greetingInfo?.totalCoins ? greetingInfo.totalCoins + totalCoinsEarned : totalCoinsEarned;
  const lifetimeCashReward = greetingInfo?.rewards;
  const vipCoinsTotal = Number(greetingInfo?.totalCoins) || 0;

  const tabButtonStyle = (isActive) => ({
    bgcolor: isActive ? 'white' : 'transparent',
    color: isActive ? 'primary.main' : 'white',
    ':hover': {
      bgcolor: 'white',
      color: 'primary.main',
    },
    boxShadow: 'none',
    borderRadius: 6, 
    fontWeight: 'bold', 
    fontSize: '0.7rem', 
    mx: 1,
    py:1.5,
    px:1,
  });

  return (
    <>
      <Box display="flex" alignItems="center" p={1} bgcolor="primary.main">
        <BackButton />
        <Typography variant="h6" component="h1" sx={{ textAlign: 'center', fontWeight: 'bold', color: 'white' }}>
          {t('rewardsDetails')}
        </Typography>
      </Box>
      <Container sx={{ bgcolor: 'primary.main', py: 3, textAlign: 'center' }}>
        <Box display="flex" justifyContent="center">
          <Button
            onClick={() => setActiveTab('lifetimeCash')}
            sx={tabButtonStyle(activeTab === 'lifetimeCash')} 
          >
            {t('lifetimeCashReward')}
          </Button>
          <Button
            onClick={() => setActiveTab('coins')}
            sx={tabButtonStyle(activeTab === 'coins')}
          >
             {t('totalCoinEarned')}
          </Button>
        </Box>
        <Box sx={{ mt: 3 }}>
        {activeTab === 'lifetimeCash' ? 
            <LifetimeCashView amount={lifetimeCashReward} /> : 
            <CoinsView coins={totalCoins} /> 
          }
          <Typography variant="caption" display="block" sx={{ color: theme.palette.text.bright, mt: 1, fontSize:'0.6rem'}}>
          {t('lastUpdate', { date: greetingInfo?.lastUpdateDate })}
                    </Typography>
        </Box>
      </Container>
      {activeTab === 'lifetimeCash' ? 
    <LifetimeCashDetails greetingInfo={greetingInfo}/> : 
    <CoinsDetails vipCoinsTotal={vipCoinsTotal} bonus={totalCoinsEarned} weeklyReferral={greetingInfo?.topDownlines}/>
}
{
  activeTab !== 'lifetimeCash' && 
    <WeeklyTargetReferralProgress downlines={greetingInfo?.topDownlines}/>
}
{activeTab === 'lifetimeCash' ? 
    <LifetimeCashRecords downlinesComm={greetingInfo?.downlinesTurnoverCommList} downlines={greetingInfo?.referralBonusList} downlineVIPBonus={greetingInfo?.downlineVIPBonus}/> : 
    <CoinsRewardRecords bonus={coinInfo} downlineVIPBonus={greetingInfo?.downlineVIPBonus} weeklyBonusHistory={greetingInfo?.weeklyBonusHistory}/>
}
    </>
  );
};

export default RewardsMain;
