import React from 'react';
import { Box, Typography, Divider } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import CountUp from 'react-countup'; 
import { useTranslation } from 'react-i18next';

const LifetimeCashDetails = ({greetingInfo, isLoading}) => {
    const theme = useTheme();
    const { t } = useTranslation(); 

    const rewards = Number(greetingInfo?.rewards) || 0;
    const availableRewards = Number(greetingInfo?.availableRewards) || 0;
    const downlinesTurnoverComm = Number(greetingInfo?.downlinesTurnoverComm) || 0;
    const referralBonus = Number(greetingInfo?.referralBonus) || 0;
    const invalidBonus = Number(greetingInfo?.invalidBonus) || 0;
    const vipBonusTotal = Number(greetingInfo?.vipBonusTotal) || 0;
    const finalReferralBonus = Math.abs(referralBonus - vipBonusTotal);
  const items = [
    { key: t('downlineTurnoverCommission'), value: downlinesTurnoverComm },
    { key: t('successfulReferralBonus'), value: finalReferralBonus },
    { key: t('vipDownlineUpgrade'), value: vipBonusTotal},
    { key: t('expiryCancelled'), value: invalidBonus, isGrey: true },  
    { divider: true },
    { key: t('totalClaimedAmount'), value: rewards, special: true },
    { key: t('availableRewards'), value: availableRewards, special: true }
];
  return (
    <Box sx={{ borderRadius: 2, mx: 3, my: 1, boxShadow: 1, overflow: 'hidden' }}>
    <Box sx={{ bgcolor: '#CCCCCC', p: 1.5 }}>  
        <Typography variant="subtitle2" sx={{ fontWeight: 'bold', textAlign: 'left' }}>
            {t('rewardsDetails')}
        </Typography>
    </Box>
    {items.map((item, index) => (
        item.divider ? (
            <Divider key={index} sx={{mx:1.5}}/>
        ) : (
            <Box key={index} display="flex" justifyContent="space-between" alignItems="center" px={1.5} py={1}>

               <Typography variant="subtitle2" color={item.isGrey ? theme.palette.ui.error : 'black'} sx={{ fontWeight: 'bold' }}>{item.key}</Typography>
               <Typography variant="body2" color={item.isGrey ? theme.palette.ui.error : 'primary'} sx={{ fontWeight: 'bold', fontSize:'0.75rem',  }}>
                            <CountUp end={item.value} duration={2.75} prefix="" suffix=" BDT" decimals={2} preserveValue />
                </Typography>
            </Box>
        )
    ))}
</Box>
  );
};

export default LifetimeCashDetails;
