import React from 'react';
import { Box, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import CountUp from 'react-countup'; 
import { useTranslation } from 'react-i18next';

const CoinsDetails = ({vipCoinsTotal, bonus}) => {
    const theme = useTheme();
    const { t } = useTranslation(); 

  const items = [
    { key: t('weeklySuccessfulReferral'), value: '3,000 Coins' },
    { key: t('successfulReferralBonus'), value: bonus },
    { key: t('vipDownlineUpgrade'), value: vipCoinsTotal }
  ];

  return (
    <Box sx={{ borderRadius: 2, mx: 3, my: 2, boxShadow: 1 }}>
    <Box sx={{ bgcolor: '#CCCCCC', borderRadius: '8px 8px 0 0', p: 1.5 }}>
      <Typography variant="subtitle2" sx={{ fontWeight: 'bold', textAlign: 'left' }}>
      {t('rewardsDetails')}
      </Typography>
    </Box>
    {items.map((item, index) => (
      <Box key={index} display="flex" justifyContent="space-between" alignItems="center" px={1.5} py={1}>
        <Typography variant="subtitle2" color={theme.palette.ui.error} sx={{ fontWeight: 'bold' }}>{item.key}</Typography>
        <Typography variant="body2" color={'primary'} sx={{ fontWeight: 'bold', fontSize:'0.75rem' }}>   
         <CountUp end={item.value} duration={2.75}/> 
        </Typography>
      </Box>
    ))}
  </Box>
  );
};

export default CoinsDetails;
