import { useState, useEffect } from 'react';

export const useFetchReferrals = (topDownlines, perPage) => {
  const [referrals, setReferrals] = useState([]);
  const [page, setPage] = useState(1);

  useEffect(() => {
    const newReferrals = topDownlines.slice(0, page * perPage);
    setReferrals(newReferrals);
  }, [topDownlines, page, perPage]);

  const loadMore = () => setPage(prevPage => prevPage + 1);

  return { referrals, loadMore };
};
