import React from 'react';
import { useNavigate } from 'react-router-dom'; 
import { Box } from '@mui/material';
import ClickableBannerEN from '../../assets/images/NewBannerEN.png';
import ClickableBannerBN from '../../assets/images/NewBannerBN.png';
import InfoIconImage from '../../assets/images/Icon_info.png'; 
import { useTranslation } from 'react-i18next';

const Banner = () => {
    const navigate = useNavigate(); 
    const { i18n } = useTranslation();
    const bannerImage = i18n.language === 'bn' ? ClickableBannerBN : ClickableBannerEN;

    const handleRedirect = () => {
        navigate('/terms');
    };
  
    return (
      <Box 
        sx={{
          height: 150, 
          overflow: 'hidden', 
          boxShadow: '0px 3px 10px rgba(0, 0, 0, 0.2)', 
          borderRadius: 2,
          '&:hover': {
            boxShadow: '0px 5px 15px rgba(0, 0, 0, 0.3)',
          },
          display: 'flex',
          justifyContent: 'center', 
          alignItems: 'center',
        }}
      > 
          <img
            src={InfoIconImage}
            alt="Info"
            style={{ width: '100%', height: '100%', position: 'absolute', 
            top: 65,
            right: 25,
            width: 15,
            height: 15}}
          />
      <img
        src={bannerImage}
        alt="Banner"
        onClick={handleRedirect}
        style={{ cursor: 'pointer',width: 350}}
      />
      </Box>
    );
  };

export default Banner;
