import React from 'react';
import { CssBaseline, ThemeProvider } from '@mui/material';
import theme from './assets/styles/theme';
import AppRoutes from './Route'; 
import '../src/locales/i18n';
import { UserInfoProvider } from './contexts/userInfoContext';
import useGoogleAnalytics from './hooks/useGoogleAnalytics'; 


function App() {
  useGoogleAnalytics('G-HMV0CEPRQX');

  return (
    <React.StrictMode>
      <ThemeProvider theme={theme}>
      <CssBaseline /> 
      <UserInfoProvider> 
      <AppRoutes />
      </UserInfoProvider> 
    </ThemeProvider>
    </React.StrictMode>
  );
}

export default App;
