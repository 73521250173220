import React from 'react';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  TableContainer,
  Paper,
  Box
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useTheme } from '@mui/material/styles';
import MonetizationOnIcon from '../../assets/images/Lucky Spin_Gold_Assets_coin.png';

const AccordionPanel = ({ panel, expanded, onChange, tableData, sortHandler }) => {
  const theme = useTheme();

  const columnToDataKeyMap = {
    date: 'dateIssue',         
    user: 'user',           
    bonus: 'creditAmount',
  };


  return (
    <Accordion
      key={panel.id}
      disabled={!(tableData[panel.id] && tableData[panel.id].length)}
      expanded={expanded === panel.id}
      onChange={() => onChange(panel.id)}
      elevation={0}
      square
      sx={{ '&:before': { display: 'none' } }}
    >
      <AccordionSummary expandIcon={<ExpandMoreIcon />} sx={{ p: 0 }}>
        <Typography sx={{fontWeight:'bold', fontSize:'1rem'}}>{panel.title}</Typography>
      </AccordionSummary>
      <AccordionDetails sx={{ p: 0 }}>
        <TableContainer component={Paper} sx={{ maxHeight: 400, overflow: 'auto', backgroundColor:'#f5f5f5', boxShadow:'none' }}>
          <Table size="small" aria-label="a dense table" sx={{ minWidth: 280 }}> 
            <TableHead>
              <TableRow>
                {['Date', 'User', 'Bonus'].map((headCell) => (
                  <TableCell key={headCell} sx={{ px: 0.5, py: 1, pl: 1.5, overflowX: 'hidden', textAlignLast: 'center' }}>
                  <TableSortLabel
                    active={sortHandler.sortConfig?.key === columnToDataKeyMap[headCell.toLowerCase()]}
                    direction={sortHandler.sortConfig?.key === columnToDataKeyMap[headCell.toLowerCase()] ? sortHandler.sortConfig.direction : 'asc'}
                    onClick={() => sortHandler.requestSort(columnToDataKeyMap[headCell.toLowerCase()])}
                      >
                      {headCell}
                    </TableSortLabel>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
            {(tableData[panel.id] || []).map((row, index) => (
                  <TableRow key={index}>
                  <TableCell sx={{fontSize:'0.5rem', textAlignLast:'center', fontWeight:'bold'}}>{row.date}</TableCell>
                  <TableCell sx={{fontSize:'0.75rem', textAlignLast:'center', fontWeight:'bold', padding:0}}>{row.user}</TableCell>
                  <TableCell sx={{ fontSize: '0.75rem', textAlign: 'center', fontWeight: 'bold', padding: 0, color: theme.palette.primary.main }}>
                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                      <img src={MonetizationOnIcon} alt="Bonus" style={{ width: '1rem', marginRight: '0.5rem' }} />
                      +{row.bonus}
                    </Box>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </AccordionDetails>
    </Accordion>
  );
};

export default AccordionPanel;
