import React from 'react';
import { Typography, CardContent, Box, Divider,Skeleton } from '@mui/material';
import { useTranslation } from 'react-i18next';
import ReferralSuccess from '../../components/common/referralsData';


const UserGreeting = ({ userName, totalDownlines, success, isLoading }) => {
  const { t } = useTranslation(); 
  return (
    <CardContent>
      {isLoading ? (
        <Skeleton variant="text" sx={{ fontSize: '1rem', height: '2.5rem', width: '80%', mx: 'auto' }} animation="wave"/>
      ) : (
        <Typography variant="h6" component="div" gutterBottom sx={{ textAlign: 'center', fontWeight: 'bold',  }}>
        {t('welcomeBack', { name: userName })}
      </Typography>
      )}
       <ReferralSuccess referrals={totalDownlines} success={success} isLoading={isLoading} />
    </CardContent>
  );
};

export default UserGreeting;
