import React from 'react';
import { Select, MenuItem, ListItemIcon, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import EnCountryIcon from '../../assets/images/Icon_English Language.png'; 
import BnCountryIcon from '../../assets/images/Icon_Bangladesh language.png';

const LanguageSelector = () => {
  const { i18n } = useTranslation();

  const handleChangeLanguage = (event) => {
    i18n.changeLanguage(event.target.value);
  };

  return (
    <Select
      value={i18n.language}
      onChange={handleChangeLanguage}
      displayEmpty
      inputProps={{ 'aria-label': 'Without label' }}
      sx={{ 
        ml: 2,
        color: 'white',
        borderColor: 'transparent', 
        '& .MuiOutlinedInput-notchedOutline': {
          border: 'none', 
        },
        '&:hover .MuiOutlinedInput-notchedOutline': {
          border: 'none', 
        },
        '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
          border: 'none', 
          boxShadow: 'none', 
        },
        height: 32, 
        '& .MuiSelect-select': {
          paddingTop: 0, 
          paddingBottom: 0,
          writingMode: 'vertical-rl'
        },
        display: 'flex',
        alignItems: 'center',
        '.MuiSvgIcon-root': {
          display: 'none',
        },
      }}
      renderValue={(selected) => {
       if (selected === 'bn') {
          return <img src={BnCountryIcon} alt="Bn" style={{ height: '24px' }} />;
        }else if (selected === 'en') {
          return <img src={EnCountryIcon} alt="En" style={{ height: '24px' }} />;
        }
        return null;
      }}
    >
      <MenuItem value="en">
        <ListItemIcon>
          <img src={EnCountryIcon} alt="En" style={{ height: '24px' }} />
        </ListItemIcon>
        <Typography variant="inherit">En</Typography>
      </MenuItem>
      <MenuItem value="bn">
        <ListItemIcon>
          <img src={BnCountryIcon} alt="Bn" style={{ height: '24px' }} />
        </ListItemIcon>
        <Typography variant="inherit">Bn</Typography>
      </MenuItem>
    </Select>
  );
};

export default LanguageSelector;
