import React from 'react';
import CheckCircleIcon from '../../assets/images/Icon_Success.png'; 
import PeopleIcon from '../../assets/images/Icon_Referrals.png'; 
import { Typography, Box, Divider, Skeleton } from '@mui/material';
import { useTranslation } from 'react-i18next';
import CountUp from 'react-countup'; 

const ReferralSuccess = ({ referrals, success, isLoading }) => {
    const { t } = useTranslation(); 

    return (
        <Box sx={{ display: 'flex', justifyContent: 'space-evenly', alignItems: 'center', mt: 2, ml: 3 }}>
            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', mr: 2 }}>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    {isLoading ? (
                        <Skeleton variant="circular" width={15} height={15} sx={{ marginRight: '8px' }} animation="wave" />
                    ) : (
                        <img src={PeopleIcon} alt={t('referrals')} style={{ width: '15px', height: '15px', marginRight: '8px' }} />
                    )}
                    {isLoading ? (
                        <Skeleton variant="text" width={60} height={20} animation="wave"/>
                    ) : (
                        <Typography variant="subtitle1" fontWeight={'bold'} sx={{}}>
                            {t('referrals')}
                        </Typography>
                    )}
                </Box>
                {isLoading ? (
                    <Skeleton variant="text" width={80} height={30} sx={{ marginTop: '8px', marginLeft: 'auto' }} animation="wave" />
                ) : (
                    <Typography variant="h3" sx={{ fontWeight: 'bold', marginLeft: 'auto',  }} component="div">
                        <CountUp end={referrals} duration={1.1}/>
                    </Typography>
                )}
            </Box>
            {isLoading ? (
                    <Skeleton orientation="vertical" sx={{ mx: 2, height: '85px' }} animation="wave"/>
          ) : (
            <Divider orientation="vertical" flexItem sx={{ mx: 2, height: '85px', backgroundColor: 'black' }} />
          )}
            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', mr: 2 }}>
                <Box sx={{ display: 'flex', alignItems: 'center', ml: 2 }}>
                    {isLoading ? (
                        <Skeleton variant="text" width={60} height={20} sx={{ marginRight: '8px' }} animation="wave"/>
                    ) : (
                        <Typography variant="subtitle1" fontWeight={'bold'} sx={{ marginRight: '8px',  }}>
                            {t('success')}
                        </Typography>
                    )}
                    {isLoading ? (
                        <Skeleton variant="circular" width={15} height={15} sx={{ marginRight: '10px' }} animation="wave"/>
                    ) : (
                        <img src={CheckCircleIcon} alt={t('success')} style={{ width: '15px', height: '15px', marginRight: '10px',  }} />
                    )}
                </Box>
                {isLoading ? (
                    <Skeleton variant="text" width={80} height={30} sx={{ marginTop: '8px', marginRight: 'auto', marginLeft: '20px' }} animation="wave"/>
                ) : (
                    <Typography variant="h3" sx={{ fontWeight: 'bold', marginRight: 'auto', marginLeft: '20px',  }} component="div">
                        <CountUp end={success} duration={1.1} />
                    </Typography>
                )}
            </Box>
        </Box>
    );
};

export default ReferralSuccess;
