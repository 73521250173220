import React, { useState } from 'react';
import { Box, Typography, Tooltip, ClickAwayListener, IconButton } from '@mui/material';
import { ProgressLabel } from './progressLabel';
import { useTheme } from '@mui/material/styles';
import Pending from '../../assets/images/warning_pending.png';
import Failed from '../../assets/images/warning_failed.png';
import Incomplete from '../../assets/images/warning_incomplete.png';
import Needed from '../../assets/images/warning_KYC needed.png';
import maskUsername from '../../utils/maskNameUtils';
import { useTranslation } from 'react-i18next';

export const ReferralItem = ({ referral, getLevelColor, calculateUserLevel, calculateProgressToNextLevel, calculateProgressPercentage }) => {
  const theme = useTheme();
  const [openTooltip, setOpenTooltip] = useState(null);
  const { t } = useTranslation(); 

  const getStatusIcon = (status) => {
    switch (status) {
      case 'Pending':
        return { icon: Pending, message: t('pendingApproval')};
      case 'Rejected':
        return { icon: Failed, message: t('kycFailed') };
      case 'Incomplete':
        return { icon: Incomplete, message: 'Incomplete Profile' };
      case 'NeedKYC':
        return { icon: Needed, message: t('kycNeeded') };
      default:
        return null;
    }
  };

  const userLevel = calculateUserLevel(referral.refereeTotalDeposit);
  const levelColor = getLevelColor(userLevel);
  const statusInfo = getStatusIcon(referral.refereeKYCStatus);
  const hasStatusIcon = !!statusInfo; 
  const isKYCFailed = referral.refereeKYCStatus === 'Rejected';

  const handleTooltipOpen = (id) => () => {
    setOpenTooltip(id);
  };

  const handleTooltipClose = () => {
    setOpenTooltip(null);
  };

  return (
    <ClickAwayListener onClickAway={handleTooltipClose}>
      <Box sx={{ mb: 2.5, px: 1 }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 0.5, px: 1 }}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Typography variant="caption" sx={{ fontWeight: 'bold', color: hasStatusIcon ? theme.palette.ui.line : 'black'}}>
              {maskUsername(referral.referee)}
            </Typography>
            {levelColor !== 'none' ? (
              <Typography component="span" sx={{ 
                bgcolor: levelColor, 
                borderRadius: '2px', 
                px: 0.5, py: '2px', 
                fontSize: '0.65rem', 
                ml: 1,
                width: 55,
                textAlign: 'center',
                color: 'white',
                fontWeight: 'bold' }}>
                {calculateUserLevel(referral.refereeTotalDeposit)}
              </Typography>
            ) : null}
            {statusInfo && (
              <Tooltip 
                title={statusInfo.message}
                placement="top"
                arrow
                open={openTooltip === referral.referee}
                disableHoverListener
                disableTouchListener
              >
                <IconButton onClick={handleTooltipOpen(referral.referee)} size="small" sx={{ ml: 1 }}>
                  <img src={statusInfo.icon} alt={statusInfo.message} style={{ height: '18px' }} />
                </IconButton>
              </Tooltip>
            )}
          </Box>
          <Typography variant="caption" sx={{ fontWeight: 'bold', fontSize: '0.6rem', color: theme.palette.ui.line }}>
          {referral.refereeKYCStatus === 'Rejected' ? 'N/A' : calculateProgressToNextLevel(referral.refereeTotalDeposit)}
          </Typography>
        </Box>
        <ProgressLabel
          value={calculateProgressPercentage(referral.refereeTotalDeposit)}
          label={`${referral.refereeTotalDeposit.toLocaleString()} BDT`}
          hasStatusIcon={hasStatusIcon}
          isKYCFailed={isKYCFailed}
        />
      </Box>
    </ClickAwayListener>
  );
};
