export const statusLabels = {
  '0': 'Active',
  '1': 'Claimed',
  '2': 'Expired',
  '3': 'Cancelled'
};

export const successfulReferralStatusLabels = {
  Pending: 'Active',
  Assigned: 'Claimed',
  Rejected: 'Cancelled',
  Expired: 'Expired',
};



export const sortData = (array, orderBy, orderDirection) => {
  return [...array].sort((a, b) => {
      if (a[orderBy] < b[orderBy]) {
          return orderDirection === 'asc' ? -1 : 1;
      }
      if (a[orderBy] > b[orderBy]) {
          return orderDirection === 'asc' ? 1 : -1;
      }
      return 0;
  });
};;


export const formatDate = (dateString) => {
  const date = new Date(dateString);
  const options = { weekday: 'long', year: 'numeric', month: 'short', day: 'numeric' };
  let formattedDate = new Intl.DateTimeFormat('en-US', options).format(date);

  formattedDate = formattedDate.replace(/,\s(?=\d)/, ' ');
  return formattedDate; 
};
