import React, { useState, useEffect } from 'react';
import { Box, Typography } from '@mui/material';
import useSortableData from '../../hooks/useSortedData';
import maskUsername from '../../utils/maskNameUtils';
import { formatDate } from '../../utils/rewardDetailsUtils';
import AccordionPanel from '../../components/reward/accordionPanel';
import { useTranslation } from 'react-i18next';

const CoinRewardRecords = ({ bonus, downlineVIPBonus, weeklyBonusHistory }) => {
  const { t } = useTranslation(); 
  const [expanded, setExpanded] = useState(false);
  const { items: sortedData, requestSort, sortConfig } = useSortableData(bonus);
  const { items: sortedVIPBonus } = useSortableData(downlineVIPBonus);
  const { items: sortedWeeklyBonusHistory } = useSortableData(weeklyBonusHistory);

  useEffect(() => {
  }, [bonus, downlineVIPBonus]);

  const handleAccordionChange = panelId => {
    setExpanded(expanded === panelId ? false : panelId);
  };

  const panels = [
    { id: 'weeklySuccessfulReferral', title:  t('weeklySuccessfulReferral')  },
    { id: 'successfulreferralbonus', title: t('successfulReferralBonus') },
    { id: 'referralDownlineVIPUpgrade', title: t('vipDownlineUpgrade') }
  ];

  const formattedData = sortedData.map(coin => ({
    date: formatDate(coin.dateIssue),
    user: maskUsername(coin.user),
    bonus: coin.bonus,
  }));

  const formattedVIPData = sortedVIPBonus.map(item => ({
    date: formatDate(item.createdTime),
    user: maskUsername(item.referee),
    bonus: item.coins,
  }));

  const formattedWeeklyBonusHistoryData = sortedWeeklyBonusHistory.map(item => ({
    date: formatDate(item.createdTime),
    user: maskUsername(item.referrer),
    bonus: item.coins,
  }));

  const tableData = {
    'weeklySuccessfulReferral': formattedWeeklyBonusHistoryData,
    'successfulreferralbonus': formattedData,
    'referralDownlineVIPUpgrade': formattedVIPData,
  };

  return (
    <Box sx={{ my: 3, mx: 3 }}>
        <Typography variant="h6" sx={{ fontWeight:'bold', fontSize:'0.9rem' }}>
        {t('coinEarningHistory')}
      </Typography>
      {panels.map((panel) => (
        <AccordionPanel
          key={panel.id}
          panel={panel}
          expanded={expanded}
          onChange={handleAccordionChange}
          tableData={tableData}
          sortHandler={{ sortConfig, requestSort }}
        />
      ))}
    </Box>
  );
};

export default CoinRewardRecords;
