import React, { useState } from 'react';
import { Button, Box, Slide } from '@mui/material';
import ShareIcon from '@mui/icons-material/Share';
import LinkIcon from '@mui/icons-material/Link';
import SocialShare from '../terms/socialShare';
import CustomSnackbar from '../common/snackBar'; 
import { useTranslation } from 'react-i18next';

const ReferralCode = ({ urlCode }) => {
  const [copied, setCopied] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [modalOpen, setModalOpen] = useState(false);
  const { t } = useTranslation(); 

  const handleCopy = () => {
    navigator.clipboard.writeText(urlCode);
    setCopied(true);
    setSnackbarOpen(true); 
    setSnackbarMessage(t('copySuccessfully'));
    setTimeout(() => setCopied(false), 2000); 
  };

  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };

  return (
      
              <><Box sx={{ display: 'flex', justifyContent: 'center', gap: 1 }}>
          <Button
              variant="contained"
              startIcon={<ShareIcon sx={{ width: '15px', height: '15px' }} />}
              onClick={() => setModalOpen(true)}
              color='secondary'
              size='small'
          >
              {t('share')}
          </Button>
          <Button
              variant="contained"
              startIcon={<LinkIcon sx={{ width: '15px', height: '15px' }} />}
              onClick={handleCopy}
              color='secondary'
              size='small'
          >
              {t('copy')}
          </Button>
      </Box><SocialShare open={modalOpen} onClose={() => setModalOpen(false)} url={urlCode} TransitionComponent={Slide} TransitionProps={{ direction: 'up' }} /><CustomSnackbar open={snackbarOpen} handleClose={handleCloseSnackbar} message={snackbarMessage} /></>
  );
};

export default ReferralCode;
