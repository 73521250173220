import { format } from 'date-fns';
import moment from 'moment-timezone';

/**
 * Formats a date into a string "Last Update on HH:mm DD MMM YYYY".
 * @param {Date|string|number} date - The date to format.
 * @returns {string} The formatted date string.
 */
export const formatDateTime = (date) => {
  try {
    const bangladeshTime = moment.tz('Asia/Dhaka');
  return `${bangladeshTime.format('HH:mm DD MMM YYYY')}`;
  } catch (error) {
    console.error('Error formatting date:', error);
    return '';
  }
};
