import React from 'react';
import { Box } from '@mui/material';
import MachiMainBanner from '../../assets/images/logoTrans.png'

const MachiBanner = () => {
    return (
        <Box flexGrow={1} display="flex" justifyContent="center">
        <img src={MachiMainBanner} alt="MachiLogo" style={{ height: '20px' }} /> 
      </Box>
    );
  };

export default MachiBanner;
