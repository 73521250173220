import React from 'react';
import { useTranslation } from 'react-i18next'; 
import bannerBN from '../../assets/images/NewBannerBN.png'; 
import bannerEN from '../../assets/images/NewBannerEN.png'; 

const TermsBanner = () => {
  const { i18n } = useTranslation(); 

  const bannerImage = i18n.language === 'bn' ? bannerBN : bannerEN;

  return (
    <div style={{ textAlign: 'center' }}>
      <img src={bannerImage} alt="Refer and Earn Reward" width={350} style={{boxShadow: '0px 3px 10px rgba(0, 0, 0, 0.2)', borderRadius:6}} />
    </div>
  );
};

export default TermsBanner;
