import React from 'react';
import Button from '@mui/material/Button';
import CloseIcon from '@mui/icons-material/Close';
import { useTheme } from '@mui/material/styles';
import {useNavigate} from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const CloseButton = () => {
  const navigate = useNavigate();   
  const theme = useTheme();
  const { t } = useTranslation(); 
  

  const handleClose = () => {
      window.location.href = 'https://m.mcb777.com/';
    localStorage.clear();  
  };
  

  return (
    <Button
      onClick={handleClose}
      startIcon={<CloseIcon sx={{ 
        borderRadius: '50%', 
        width: 15,
        height: 15, 
        display: 'flex', 
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: theme.palette.primary.main, 
        color: theme.palette.common.white, 
      }} />}
      sx={{
        color: theme.palette.info.dark, 
        textTransform: 'none', 
        fontWeight: 'bold', 
        fontSize: theme.typography.body2.fontSize,
        ml:3
      }}
    >
          {t('close')}
    </Button>
  );
};

export default CloseButton;
