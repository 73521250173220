import React from 'react';
import { Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import CountUp from 'react-countup'; 

const LifetimeCashView = ({ amount }) => {
  const theme = useTheme();

  return (
  <Typography variant="h4" component="div" color={theme.palette.text.bright} sx={{ fontWeight: 'bold',  }}>
    <CountUp end={amount} duration={2.75} decimals={2} suffix=' BDT'/>
  </Typography>
);
};

export default LifetimeCashView;
