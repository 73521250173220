import axios from 'axios';

const machiSpinApi = axios.create({
  baseURL: 'https://api.machispinwin.com/API',
});

const machiBoApi = axios.create({
    baseURL: 'https://api.machibo.com/API',
  });
export const getUserInfo = async (memberID) => {
  try {
    const response = await machiBoApi.get(`/GetReferrerInfo?MemberID=${memberID}`);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getSpinMemberInfo = async (userName) => {
  try {
    const response = await machiSpinApi.post(`/GetMemberInfo?Username=${userName}`);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getSpinCoinInfo = async (userName, language) => {
  try {
    const response = await machiSpinApi.post(`/GetCoinHistory?Username=${userName}&Locale=${language}`);
    return response.data;
  } catch (error) {
    throw error;
  }
};
