import { useContext, useMemo } from 'react';
import { UserInfoContext } from '../contexts/userInfoContext';

export const useSpinCoinInfo = () => {
  const { spinCoinInfo, isLoading, error } = useContext(UserInfoContext);

  const coinInfo = useMemo(() => {
    if (!spinCoinInfo) return [];
    
 return spinCoinInfo.data.reduce((acc, item) => {
      const refereeMatch = item.formula.match(/Referee\s*:\s*(\w+)/);
      if (refereeMatch && refereeMatch[1]) {
        acc.push({
          dateIssue: item.dateIssue,
          user: refereeMatch[1],
          bonus: item.creditAmount,
        });
      }
      return acc;
    }, []);
  }, [spinCoinInfo]);
  return { coinInfo: coinInfo, isLoading, error };
};
