import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  TableContainer,
  Paper,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useTheme } from '@mui/material/styles';
import maskUsername from '../../utils/maskNameUtils';
import {formatDate} from '../../utils/rewardDetailsUtils';
import { useTranslation } from 'react-i18next';

const sortData = (array, orderBy, orderDirection) => {
  return [...array].sort((a, b) => {
      if (a[orderBy] < b[orderBy]) {
          return orderDirection === 'asc' ? -1 : 1;
      }
      if (a[orderBy] > b[orderBy]) {
          return orderDirection === 'asc' ? 1 : -1;
      }
      return 0;
  });
};

const CashRewardRecords = ({downlinesComm, downlines, downlineVIPBonus}) => {
  const { t } = useTranslation(); 
    const theme = useTheme();
    const [activeTab, setActiveTab] = useState(t('all'));
    const [expanded, setExpanded] = useState(false);
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('');
  const [filteredData, setFilteredData] = useState(downlines);
  const [filteredCommData, setFilteredCommData] = useState(downlinesComm);
  const [filteredDownlineVIPBonusData, setFilteredDownlineVIPBonusData] = useState(downlineVIPBonus);

  const successfulReferralStatusLabels = {
    Pending: t('active'),
    Claimed: t('claimed'),
    Rejected: t('cancelled'),
    Expired: t('expired'),
  };
  
  const statusLabels = {
    '0': t('active'),
    '1': t('claimed'),
    '2': t('expired'),
    '3': t('cancelled')
  };

  
  const getSuccessfulReferralStatusLabel = (status) => {
    let label = successfulReferralStatusLabels[status];
    
    if (!label) {
      label = statusLabels[status];
    }
    
    return label || status;
  };
  
  const getStatusLabel = (statusCode) => statusLabels[statusCode] || 'Unknown';
  
  const columnToDataKeyMap = {
    date: 'created_at',         
    user: 'username',           
    bonus: 'credit',
    status: 'status'   
  };


  useEffect(() => {
    setFilteredData(downlines);
    setFilteredCommData(downlinesComm);
    setFilteredDownlineVIPBonusData(downlineVIPBonus);
  }, [downlines, downlinesComm, downlineVIPBonus]);

  const tabButtonStyle = (tabName) => ({
    borderRadius: 2,
    border:'solid',
    borderWidth:'thin',
    fontSize:'0.6rem',
    fontWeight:'bold',
    margin: '0 5px',
    padding: '5px 15px',
    textTransform: 'none',
    minWidth:58,
    flexGrow: 1,
    color: activeTab === tabName ? 'white' : '',
    backgroundColor: activeTab === tabName ? theme.palette.ui.tabs : '',
    '&:hover': {
      backgroundColor: activeTab === tabName ? theme.palette.ui.tabs : '#e3f2fd',
    },
  });

  const handleTabClick = (tabName) => {
    setActiveTab(tabName);
        //setExpanded(false);
          if (tabName === t('all')) {
            setFilteredData(downlines);
            setFilteredCommData(downlinesComm);
            setFilteredDownlineVIPBonusData(downlineVIPBonus);
          } else {
            const statusRefCode = Object.keys(successfulReferralStatusLabels).find(key => successfulReferralStatusLabels[key].toLowerCase() === tabName.toLowerCase());
            const statusCode = Object.keys(statusLabels).find(key => statusLabels[key].toLowerCase() === tabName.toLowerCase());
            setFilteredData(downlines.filter(x => x.status === statusRefCode));
            setFilteredCommData(downlinesComm.filter(x => x.status === statusCode));
            setFilteredDownlineVIPBonusData(downlineVIPBonus.filter(x => x.status === statusCode));
          }
  };

 const handleAccordionChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const handleRequestSort = (property) => (event) => {
    setOrderBy(property);
    setOrder((prevOrder) => {
      const isAsc = orderBy === property && prevOrder === 'asc';
      const newOrder = isAsc ? 'desc' : 'asc';
      setFilteredData(sortData(filteredData, property, newOrder));
      setFilteredCommData(sortData(filteredCommData, property, newOrder));
      setFilteredDownlineVIPBonusData(sortData(filteredDownlineVIPBonusData, property, newOrder));
      return newOrder; 
    });
  };
  

const createSortHandler = (column) => (event) => {

  const property = columnToDataKeyMap[column.toLowerCase()] || column;
  handleRequestSort(property)(event);
};

  const panels = [
    { id: 'successfulReferral', title: t('successfulReferral') },
    { id: 'downlineTurnoverCommission', title:  t('downlineTurnoverCommission')  },
    { id: 'referralDownlineVIPUpgrade', title: t('vipDownlineUpgrade') }
  ];
  
  const formattedDownlines = filteredData.map(downline => ({
    date: formatDate(downline.created_at),
    user: maskUsername(downline.username),
    bonus: Number(downline.credit).toFixed(2),
    status: getSuccessfulReferralStatusLabel(downline.status),
  }));

  const formattedCommData = filteredCommData.map(comm => ({
    date: formatDate(comm.created_at),
    user: maskUsername(comm.username),
    bonus: comm.credit,
    status: getStatusLabel(comm.status),
}));

const formattedReferralDownlineVIPUpgrade = filteredDownlineVIPBonusData.map(downlineVIPBonus => ({
  date: formatDate(downlineVIPBonus.createdTime),
  user: maskUsername(downlineVIPBonus.referee),
  bonus: downlineVIPBonus.bonus,
  status: getSuccessfulReferralStatusLabel(downlineVIPBonus.status),
}));

  const tableData = {
    'successfulReferral': formattedDownlines,
    'downlineTurnoverCommission': formattedCommData,
    'referralDownlineVIPUpgrade': formattedReferralDownlineVIPUpgrade,
  };
  

  return (
    <Box sx={{my: 3, mx: 3}}>
  <Typography variant="h6" sx={{ fontWeight: 'bold', fontSize: '0.9rem' }}>
  {t('cashRewardRecords')}
  </Typography>
  <Box sx={{ display: 'flex', justifyContent: 'center', my: 1.5 }}>
        {[t('all'), t('active'), t('claimed'), t('expired'), t('cancelled')].map(tab => (
          <Button key={tab} sx={tabButtonStyle(tab)} onClick={() => handleTabClick(tab)}>
            {tab.charAt(0).toUpperCase() + tab.slice(1)}
          </Button>
        ))}
      </Box>
  {panels.map((panel) => (
    <Accordion
      key={panel.id}
      disabled={!(tableData[panel.id] && tableData[panel.id].length)}
      expanded={expanded === panel.id}
      onChange={handleAccordionChange(panel.id)}
      elevation={0}
      square
      sx={{ '&:before': { display: 'none' } }}
    >
      <AccordionSummary expandIcon={<ExpandMoreIcon />} sx={{ p: 0 }}>
        <Typography sx={{fontWeight:'bold', fontSize:'1rem'}}>{panel.title}</Typography>
      </AccordionSummary>
      <AccordionDetails sx={{ p: 0 }}>
        <TableContainer component={Paper} sx={{ maxHeight: 400, overflow: 'auto', backgroundColor:'#f5f5f5', boxShadow:'none' }}>
          <Table stickyHeader size="small" aria-label="a dense table" sx={{ minWidth: 280 }}> 
            <TableHead padding='0'>
              <TableRow>
              {(panel.id !== 'successfulReferral' ? ['Date', 'User', 'Bonus', 'Status'] : ['Date', 'Bonus', 'Status']).map((headCell) => (
                 <TableCell key={headCell} sx={{ px: 0.5, py: 1, pl: 1.5, overflowX: 'hidden', textAlignLast: 'center', backgroundColor:'#f5f5f5' }}>
                   <TableSortLabel
                      active={orderBy === columnToDataKeyMap[headCell.toLowerCase()]}
                      direction={orderBy === columnToDataKeyMap[headCell.toLowerCase()] ? order : 'asc'}
                      onClick={createSortHandler(headCell.toLowerCase())}
                    >
                      {headCell}
                    </TableSortLabel>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
            {(tableData[panel.id] || []).map((row, index) => (
                <TableRow key={index}>
                  <TableCell sx={{fontSize:'0.5rem', textAlignLast:'center', fontWeight:'bold', color: (row.status === 'Expired' || row.status === 'Cancelled') ? theme.palette.ui.line :  theme.palette.text.normal}}>{row.date}</TableCell>
                  {panel.id !== 'successfulReferral' && <TableCell sx={{fontSize:'0.75rem', textAlignLast:'center', fontWeight:'bold', padding:0, color: (row.status === 'Expired' || row.status === 'Cancelled') ? theme.palette.ui.line :  theme.palette.text.normal}}>{row.user}</TableCell>}
                  <TableCell sx={{fontSize:'0.75rem', textAlignLast:'center', fontWeight:'bold', padding:0, color: (row.status === 'Expired' || row.status === 'Cancelled') ? theme.palette.ui.line :  theme.palette.primary.main}}>{row.bonus+' BDT'}</TableCell>
                  <TableCell sx={{fontSize:'0.5rem', textAlignLast:'center', fontWeight:'bold', padding:0, color: (row.status === 'Expired' || row.status === 'Cancelled') ? theme.palette.ui.line :  theme.palette.text.normal}}>{row.status}</TableCell> 
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </AccordionDetails>
    </Accordion>
  ))}
</Box>
  );
};

export default CashRewardRecords;
