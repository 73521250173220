import React from 'react';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';

const BackButton = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { t } = useTranslation(); 

  const handleBackClick = () => {
    navigate(-1); 
  };

  return (
    <Button
    onClick={handleBackClick}
    startIcon={<ArrowBackIcon sx={{ 
      borderRadius: '50%', 
      width: 15,
      height: 15, 
      display: 'flex', 
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: theme.palette.primary.main, 
      color: theme.palette.common.white, 
    }} />}
    sx={{
      color: theme.palette.info.dark, 
      textTransform: 'none', 
      fontWeight: 'bold', 
      fontSize: theme.typography.body2.fontSize,
      ml:3
    }}
  >
    {t('back')}
  </Button>
  );
};

export default BackButton;