import React, { useEffect, useCallback, useRef } from 'react';
import { Card, Typography, Box, Skeleton } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@mui/material/styles';
import { useFetchReferrals } from '../../hooks/useFetchReferrals'; 
import { ReferralProgressList } from '../../components/home/referralProgressList';

const ReferralStatus = ({ topDownlines, isLoading, limit }) => {
  const { t } = useTranslation();
  const theme = useTheme();

  const perPage = 50;
//  const { referrals } = useFetchReferrals(topDownlines);
  const { referrals, loadMore } = useFetchReferrals(topDownlines, perPage);

  const observer = useRef();
  const lastReferralElementRef = useCallback(node => {
    if (isLoading) return;
    if (observer.current) observer.current.disconnect();
    observer.current = new IntersectionObserver(entries => {
      if (entries[0].isIntersecting) {
        loadMore();
      }
    });
    if (node) observer.current.observe(node);
  }, [isLoading, loadMore]);

  const displayedDownlines = limit ? referrals.slice(0, limit) : referrals;
  const getLevelColor = (level) => {
    switch (level) {
      case t('diamond'):
        return theme.palette.level.diamond;
      case t('platinum'):
        return theme.palette.level.platinum;
      case t('gold'):
        return theme.palette.level.gold;
      case t('silver'):
        return theme.palette.level.silver; 
      case t('bronze'):
        return theme.palette.level.bronze;
      default:
        return theme.palette.level.normal;;
    }
  };

  //Utils Function
   const calculateUserLevel = (totalDeposit) => {
    if (totalDeposit >= 10000000) return t('diamond');
    if (totalDeposit >= 3000000) return t('platinum');
    if (totalDeposit >= 2000000) return t('gold');
    if (totalDeposit >= 1000000) return t('silver');
    if (totalDeposit >= 300000) return t('bronze');
    return t('normal');
  };

   const getNextLevelDepositRequirement = (totalDeposit) => {
    if (totalDeposit < 300000) return 300000;
    if (totalDeposit < 1000000) return 1000000;
    if (totalDeposit < 2000000) return 2000000;
    if (totalDeposit < 3000000) return 3000000;
    if (totalDeposit < 10000000) return 10000000;
    return null;
  };
  

   const calculateProgressPercentage = (totalDeposit) => {
    const currentLevelRequirement = calculateUserLevel(totalDeposit) === t('bronze') ? 0 : getNextLevelDepositRequirement(totalDeposit - 1);
    const nextLevelRequirement = getNextLevelDepositRequirement(totalDeposit);
  
    if (!nextLevelRequirement) return 100;
  
    const progressTowardsNextLevel = (totalDeposit / currentLevelRequirement) * 100;
    return Math.min(Math.max(progressTowardsNextLevel, 0), 100);
  };
  
   const calculateProgressToNextLevel = (totalDeposit) => {
    const nextLevelRequirement = getNextLevelDepositRequirement(totalDeposit);
    if (!nextLevelRequirement) return 'Max level reached';
  
    const amountToNextLevel = nextLevelRequirement - totalDeposit;
    return `${amountToNextLevel.toLocaleString()} ${t('moreTo')} ${calculateUserLevel(nextLevelRequirement)} level`;
  };
  

  const renderSkeletons = () => {
    return Array.from(new Array(3)).map((item, index) => (
      <Box key={index} sx={{ margin: 1 }}>
        <Skeleton variant="text" width="80%" animation="wave"/>
        <Skeleton variant="rectangular" height={118} animation="wave"/>
      </Box>
    ));
  };

  return (
    <Card sx={{ bgcolor: 'background.paper', boxShadow: 3 }}>
       {isLoading ? (
                        <Skeleton variant="h6" sx={{ textAlign: 'center', fontWeight: 'bold', fontSize: '1rem', p: 1 }} animation="wave"/>
                    ) : (
                      <Typography variant="h6" gutterBottom sx={{ textAlign: 'center', fontWeight: 'bold', fontSize: '1rem', p: 1 }}>
        {t('referralsList')}
      </Typography>
                    )}
     
      <Box sx={{ maxHeight: '80vh', overflowY: 'auto' }} onScroll={({ currentTarget }) => {
      }}>
         {isLoading ? 
          renderSkeletons() :
        <ReferralProgressList
          topDownlines={displayedDownlines}
          isLoading={isLoading}
          getLevelColor={getLevelColor}
          calculateUserLevel={calculateUserLevel}
          calculateProgressToNextLevel={calculateProgressToNextLevel}
          calculateProgressPercentage={calculateProgressPercentage}
        />
      }
             {!isLoading && <div ref={lastReferralElementRef}></div>}

      </Box>
    </Card>
);
};
export default ReferralStatus;