import React, { useState } from 'react';
import { Box, Typography } from '@mui/material';
import MonetizationOnIcon from '../../assets/images/Lucky Spin_Gold_Assets_coin.png';
import MachiSpin from '../../assets/images/2024_03_Lucky Spin Widget.png';
import SpinWheelModal from '../reward/spinWheelModal';
import CountUp from 'react-countup'; 

const CoinsView = ({ coins }) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
  
    const handleOpen = () => setIsModalOpen(true);
    const handleClose = () => setIsModalOpen(false);
    return (
  <><Box display="flex" alignItems="center" color="white" fontWeight="bold" sx={{ placeContent: 'center', gap:1 }}>
        <img src={MonetizationOnIcon} alt="Coin" style={{ height: 30 }} />
        <Typography variant="h4" component="div" sx={{ fontWeight: 'bold', color: 'white', }}>
            <CountUp end={coins} duration={2.75}/>
        </Typography>
    </Box>
    <img src={MachiSpin} alt="Machi Spin" style={{ height: '65px', position: 'absolute', right: 16, top:146, cursor: 'pointer' }} onClick={handleOpen}/>
    <SpinWheelModal open={isModalOpen} handleClose={handleClose} />
</>
);
};

export default CoinsView;
