import React, { createContext, useState, useEffect } from 'react';
import { getUserInfo, getSpinMemberInfo, getSpinCoinInfo } from '../services/apiService';

export const UserInfoContext = createContext();

export const UserInfoProvider = ({ children }) => {
  const [userInfo, setUserInfo] = useState(null);
  const [spinMemberInfo, setSpinMemberInfo] = useState(null);
  const [spinCoinInfo, setSpinCoinInfo] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const decodeBase64 = (base64String) => atob(base64String);

    const fetchUserData = async () => {
      setIsLoading(true);
      try {
        const storedMemberId = localStorage.getItem('MemberId');
        const searchParams = new URLSearchParams(window.location.search);

        const encodedMemberID = searchParams.get('MemberId');

        if (encodedMemberID) {
          const decodedMemberId = decodeBase64(encodedMemberID);
          const userInfoData = await getUserInfo(decodedMemberId);
          const spinMemberData = await getSpinMemberInfo(userInfoData.data.userName);
          const spinCoinData = await getSpinCoinInfo(userInfoData.data.userName, 'bn');
          setUserInfo(userInfoData);
          setSpinMemberInfo(spinMemberData);
          setSpinCoinInfo(spinCoinData);
          
          localStorage.setItem('MemberId', decodedMemberId); 
          localStorage.setItem('Username', userInfoData.data.userName); 
        }
      } catch (err) {
        console.error('Error loading user data:', err);
        setError(err);
      } finally {
        setIsLoading(false);
      }
    };

    fetchUserData();
  }, []);

  return (
    <UserInfoContext.Provider value={{ userInfo, spinMemberInfo, spinCoinInfo, isLoading, error }}>
      {children}
    </UserInfoContext.Provider>
  );
};

export default UserInfoProvider;
